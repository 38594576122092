import styled from 'styled-components';

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
`;

export const ServicesCTA = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 60%;
  }
`;

export const ThreeServicesContainer = styled.div`
  width: 90%;
  margin-top: 15px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 80px;
  }

  @media (min-width: 1000px) {
    justify-content: space-between;
    width: 75%;
  }
`

export const TwoServicesContainer = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 63%;
  }
`

export const Title = styled.h2`
  width: 90%;
  height: 22px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  display: block;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 35px;
`;

export const Text = styled.div`
  width: 90%;
  max-width: 344px;
  height: 30px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin: auto;
  margin-bottom: 15px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    max-width: unset;
    margin: unset;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin-top: 20px;
  max-width: 385px;
  width: 100%;
  height: 40px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin: 0;
  }
`
