import styled from "styled-components";

const Balloon = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 10px;
  display: inline-block;
  min-width: 100px;
  padding: 20px 40px;
  position: relative;
  margin: 14px;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    margin: 25px;
  }
`;

const LeftBalloon = styled(Balloon)`
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 12px solid ${({ theme }): string => theme.colors.white};
    border-right: 28px solid transparent;
    position: absolute;
    bottom: 0;
    left: 10px;
    transform: translateY(100%);
  }
`;

const RightBalloon = styled(Balloon)`
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 12px solid ${({ theme }): string => theme.colors.white};
    border-left: 28px solid transparent;
    position: absolute;
    bottom: 0;
    right: 10px;
    transform: translateY(100%);
  }
`;

export const Balloon00 = styled(LeftBalloon)`
  width: 206px;
`;

export const Balloon01 = styled(LeftBalloon)`
  padding: 15px 30px;
  width: 104px;
`;

export const Balloon02 = styled(RightBalloon)`
  padding: 10px 30px;
  width: 120px;
`;

export const Balloon03 = styled(RightBalloon)`
  margin-left: 25px;
  width: 226px;
`;

export const Balloon10 = styled(LeftBalloon)`
  padding-left: 20px;
  padding-right: 20px;
  width: 154px;
`;

export const Balloon12 = styled(LeftBalloon)`
  width: 238px;
`;

export const Balloon13 = styled(RightBalloon)`
  margin-left: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 175px;
`;

export const Column = styled.div`
  flex-direction: column;
  justify-content: center;
  margin-left: 25px;
  display: none;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    display: flex;
  }

  &:first-child {
    align-items: flex-end;
    margin-left: 0px;
  }

  &:last-child {
    display: flex;
  }
`;

export const PartnersBackground = styled.div`
  background-color: ${({ theme }): string => theme.colors.grey};
  margin: 75px auto;
  padding: 40px 0;
  position: relative;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    margin: 0 auto 132px;
  }

  &:before {
    // background-color: ${({ theme }): string => theme.colors.primary};
    clip-path: ellipse(100% 92% at 50% 8%);
    content: "";
    height: 105%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @media ${({ theme }): string => theme.breakpoints.desktop} {
      height: 100%;
    }
  }
`;

export const PartnersTitle = styled.h2`
  color: ${({ theme }): string => theme.colors.primary};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
  position: relative;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    margin-bottom: 50px;
  }
`;

export const PartnersWrapper = styled.div`
  margin: auto;
  width: 80%;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    align-items: flex-start;
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;
