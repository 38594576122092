import React, { FC } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import style from './style/slick.css';

import { Author, AvatarContainer, Container, IconContainer, Quote, Slide, Text, TextHeader, Title, SliderContainer, SliderBlock, TextContent } from './style';
import userLanguage from "../../hooks/useLanguage";

const Testimonies: FC = () => {
  const language = userLanguage();
  const { frHomeReviewsBlock, enHomeReviewsBlock } = useStaticQuery(
    graphql`
      query {
        frHomeReviewsBlock: contentfulHomeReviewsBlock(node_locale: {eq: "fr"}) {
          reviews {
            author
            review {
              review
            }
            image {
              secure_url
            }
          }
          downloadAppLinks {
            link
            image {
              secure_url
            }
          }
          title
        }
        enHomeReviewsBlock: contentfulHomeReviewsBlock(node_locale: {eq: "en"}) {
          reviews {
            author
            review {
              review
            }
            image {
              secure_url
            }
          }
          downloadAppLinks {
            link
            image {
              secure_url
            }
          }
          title
        }
      }
    `
  )

  const reviews = language === "fr" ? frHomeReviewsBlock.reviews : enHomeReviewsBlock.reviews;
  const downloadAppLinks = language === "fr" ? frHomeReviewsBlock.downloadAppLinks : enHomeReviewsBlock.downloadAppLinks;
  const title = language === "fr" ? frHomeReviewsBlock.title : enHomeReviewsBlock.title;

  return (
    <Container className={style.container}>
      <Title>{title}</Title>
      <IconContainer>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {downloadAppLinks.map((item: any, i: number) => (
          <a key={i} href={item.link} style={{maxHeight: 40, maxWidth: 130}}>
            <img src={item.image[0].secure_url} key={i} style={{ height: "100%", width: "100%" }}/>
          </a>
        ))}
        
      </IconContainer>
      <SliderContainer>
        <SliderBlock>
          <Slider dots infinite speed={500} slidesToShow={typeof window !== "undefined" && screen.width > 800 ? 3 : 1} slidesToScroll={1}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {reviews.map((review: any, i: number) => (
              <Slide key={`slide-${i}`}>
                <Text>
                  <TextHeader>
                    <Quote>&rdquo;</Quote>
                    <Author>
                      {review.author}
                      <AvatarContainer><img src={review.image[0].secure_url} /></AvatarContainer>
                    </Author>
                  </TextHeader>
                  <TextContent>
                    {review.review.review}
                  </TextContent>
                </Text>
              </Slide>
            ))}
          </Slider>
        </SliderBlock>
      </SliderContainer>
    </Container>
  );
}

export default Testimonies;
