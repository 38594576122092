import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import { HttpResponse, post } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";

import { AppState } from "..";

export const sendDeeplinkSms = (
  token: string,
  phoneNumber: string
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await post<{}>(
      "/customers/phone/getDownloadLink",
      { token, phoneNumber },
      getState()
    );

    return response;
  } catch (error) {
    return error;
  }
};
