import React, { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  Link,
  ImgContainer,
  FormContainer,
  Form,
  Fieldset,
  InputContainer,
  Input,
  Button,
  LoadingButton,
} from "./style";
import AndroidStoreFR from "../../images/badge-google-play_fr";
import AndroidStoreEN from "../../images/badge-google-play_en";
import AppleStoreFR from "../../../images/badge-apple-store_fr.svg";
import AppleStoreEN from "../../../images/badge-apple-store_en.svg";

import { useToast, TOAST_TYPE } from "../../../ui/toast";
import useThunkDispatch from "../../../hooks/use-thunk-dispatch";
import { sendDeeplinkSms } from "../../../store/ajax/actions";
import userLanguage from "../../hooks/useLanguage";
import parsePhoneNumberFromString from "libphonenumber-js";
import Spinner from "../../../images/spinner-white.svg";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const IconAppleStore: FC = () => {
  const lang = userLanguage();
  switch (lang) {
    case "fr":
      return <AppleStoreFR />;
    case "en":
    default:
      return <AppleStoreEN />;
  }
};

const IconGooglePlay: FC = () => {
  const lang = userLanguage();
  switch (lang) {
    case "fr":
      return <AndroidStoreFR />;
    case "en":
    default:
      return <AndroidStoreEN />;
  }
};

const DownloadBarServices: FC = () => {
  const intl = useIntl();
  const { showToast } = useToast();
  const dispatch = useThunkDispatch();
  const [phoneNumber, setPhoneNumber] = useState("+33");
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [sendMessageSuccessful, setSendMessageSuccessful] = useState(false);
  const [token, setToken] = useState("");
  {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }
  const onValueChange = useCallback((value: string) => {
    setPhoneNumber(value);
    const phoneNumber = parsePhoneNumberFromString(value);
    if (!phoneNumber || !phoneNumber.isValid()) {
      setPhoneNumberValid(false);
    } else {
      setPhoneNumberValid(true);
    }
  }, []);

  const onSendSms = async (event: any): Promise<void> => {
    event.preventDefault();
    setSendMessageLoading(true);

    const response = await dispatch(sendDeeplinkSms(token, phoneNumber));

    if (!response.ok) {
      showToast(
        response.error ? (
          response.error.message
        ) : (
          <FormattedMessage id="comingsoon" />
        ),
        {
          type: TOAST_TYPE.ERROR,
        }
      );
    } else {
      setSendMessageSuccessful(true);
      showToast(intl.formatMessage({ id: "sms.deeplink.success" }), {
        type: TOAST_TYPE.SUCCESS,
      });
    }

    setSendMessageLoading(false);
  };

  return (
    <Container>
      <Link
        href="https://apps.apple.com/fr/app/wast-le-pressing-mobile/id1380759819"
        target="_blank"
      >
        <IconAppleStore />
      </Link>
      <Link
        href="https://play.google.com/store/apps/details?id=com.wast.wast"
        target="_blank"
      >
        <ImgContainer>
          <IconGooglePlay />
        </ImgContainer>
      </Link>
      <FormContainer>
        <Form>
          <Fieldset>
            <InputContainer>
              <Input
                type="tel"
                placeholder={intl.formatMessage({
                  id: "placeholder.number.deeplink",
                })}
                id="phone"
                name="phone"
                value={phoneNumber}
                onChange={e => onValueChange(e.target.value)}
                required
                style={phoneNumberValid ? {} : { borderColor: "red" }}
              />
              {sendMessageLoading ? (
                <LoadingButton disabled>
                  <Spinner />
                </LoadingButton>
              ) : (
                <Button
                  type="button"
                  value={intl.formatMessage({ id: "button.deeplink.download" })}
                  id="download"
                  name="download"
                  onClick={onSendSms}
                  disabled={!phoneNumberValid || sendMessageSuccessful || !token}
                />
              )}
            </InputContainer>
            <GoogleReCaptcha onVerify={token => setToken(token)} />
          </Fieldset>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default DownloadBarServices;
