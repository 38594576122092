/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import userLanguage from "./hooks/useLanguage";

interface Props {
  lang?: string;
  meta?: [];
  title: string;
  description?: string;
}

const SEO: FC<Props> = ({ lang = "fr", meta = [], title }) => {
  const language = userLanguage();
  const { frHomePage, enHomePage } = useStaticQuery(
    graphql`
      query {
        frHomePage: contentfulHomePage(node_locale: {eq: "fr"}) {
          titleMetadata
          descriptionMetadata
        }
        enHomePage: contentfulHomePage(node_locale: {eq: "en"}) {
          titleMetadata
          descriptionMetadata
        }
      }
    `
  )

  const titleMetadata = language === 'fr' ? frHomePage.titleMetadata : enHomePage.titleMetadata;
  const descriptionMetadata = language === 'fr' ? frHomePage.descriptionMetadata : enHomePage.descriptionMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang: language ? language : lang,
      }}
      title={titleMetadata ? titleMetadata : title}
      titleTemplate={titleMetadata ? `${titleMetadata}` : `${title}`}
      meta={[
        {
          name: `description`,
          content: descriptionMetadata,
        },
        {
          property: `og:title`,
          content: titleMetadata ? titleMetadata : title,
        },
        {
          property: `og:description`,
          content: descriptionMetadata,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:title`,
          content: titleMetadata ? titleMetadata : title,
        },
        {
          name: `twitter:description`,
          content: descriptionMetadata,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
