import React, { FC } from "react"
import { FormattedMessage } from "react-intl";

import ShirtIcon from "../../images/professional-icon-blue"
import ClockIcon from "../../images/clock-blue"
import PhoneIcon from "../../images/phone-blue"

import { 
	Container,
	Advantages,
	Advantage,
	IconContainer,
	IconContainerResize,
	PhoneIconContainerResize,
	TextContainer,
	Title,
	Text
} from "./style"

const AdvantagesBlockServices: FC = () => {
  return (
		<Container>
			<Advantages>
				<Advantage>
					<IconContainer>
							<IconContainerResize>
								<ShirtIcon />
							</IconContainerResize>
					</IconContainer>
					<TextContainer>
						<Title><FormattedMessage id="banner.title.one" /></Title>
						<Text><FormattedMessage id="banner.description.one" /></Text>
					</TextContainer>
				</Advantage>
				<Advantage>
					<IconContainer>
						<IconContainerResize>
							<ClockIcon />
						</IconContainerResize>
					</IconContainer>
					<TextContainer>
						<Title><FormattedMessage id="banner.title.two" /></Title>
						<Text><FormattedMessage id="banner.description.two" /></Text>
					</TextContainer>
				</Advantage>
				<Advantage>
					<IconContainer>
						<PhoneIconContainerResize>
							<PhoneIcon />
						</PhoneIconContainerResize>
					</IconContainer>
					<TextContainer>
						<Title><FormattedMessage id="banner.title.three" /></Title>
						<Text><FormattedMessage id="banner.description.three" /></Text>
					</TextContainer>
				</Advantage>
			</Advantages>
		</Container>
	);
}

export default AdvantagesBlockServices
