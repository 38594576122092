import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: ${({ theme: { breakpoints } }): string =>
      breakpoints.phoneValue}px) {
    display: none;
  }

  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme: { colors } }): string => colors.white};
  padding: 10px;
  text-align: center;
  margin-top: 10px;
`;

export const Link = styled.a`
  padding: 0;
  display: inline-block;
  line-height: 0;
  margin: 0 4px;
  text-decoration: none;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
`;

export const ImgContainer = styled.div`
  overflow: hidden;
  width: 134px;
`;

export const FormContainer = styled.div`
  min-width: 515px;
  display: inline-block !important;
  margin-left: 20px;
`;

export const Form = styled.form`
  margin: 0;
  background: transparent;
  border-radius: 3px;
  position: relative;
  padding: 0;
  border-style: none;
`;

export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border-style: none;
`;

export const InputContainer = styled.div`
  overflow: hidden;
`;

export const Input = styled.input`
  font: 100% ${({ theme: { fonts } }): string => fonts.main};
  border-radius: 26px 0 0 26px;
  border-right: 0;
  width: 60% !important;
  border: 1px solid ${({ theme: { colors } }): string => colors.lightGrey};
  background: ${({ theme: { colors } }): string => colors.white};
  box-sizing: border-box;
  float: left;
  padding: 10px 19px !important;
  margin: 0;
  vertical-align: middle;
  outline: none;
`;

export const Button = styled.input`
  border-radius: 0 26px 26px 0 !important;
  width: 40% !important;
  min-width: initial;
  float: none;
  margin: 0;
  text-align: center;
  white-space: inherit;
  background: ${({ theme: { colors } }): string => colors.white};
  border: 1px solid ${({ theme: { colors } }): string => colors.secondary};
  cursor: pointer;
  color: ${({ theme: { colors } }): string => colors.secondary};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  padding: 8px 19px !important;
  outline: none;

  &:hover {
    color: ${({ theme: { colors } }): string => colors.white};
    background-color: ${({ theme: { colors } }): string => colors.secondary};
  }
`;

export const LoadingButton = styled.button`
  border-radius: 0 26px 26px 0 !important;
  height: 43px;
  float: left;
  text-align: center;
  white-space: inherit;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  border: 1px solid ${({ theme: { colors } }): string => colors.secondary};
  cursor: pointer;
  color: ${({ theme: { colors } }): string => colors.secondary};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  outline: none;
  cursor: pointer;
`;
