import styled from "styled-components";

export const Container = styled.div`
	@media (max-width:${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
		display:none;
	}

	max-width: 1250px;
	margin: 0 auto;
	padding: 40px 10px 15px;
	overflow: hidden;
	text-align: center;
	position: relative;
`;

export const Advantages = styled.div`
`;

export const Advantage = styled.div`
	width: 32%;
	margin: 0 .2%;
	display: inline-block;
	vertical-align: top;
`;

export const IconContainer = styled.div`
	margin-right: 2%;
	margin-bottom: 0 !important;
	float: left;
	width: 24%;
	text-align: center;
`;

export const IconContainerResize = styled.div`
	display: inline-block;
    max-height: 45px;
	margin-top: 30px;
	width:45px
`;

export const PhoneIconContainerResize = styled.div`
	display: inline-block;
    max-height: 45px;
	margin-top: 30px;
	width:60px
`;

export const TextContainer = styled.div`
	float: left;
	width: 74%;
	text-align: left;
	font-family: ${({ theme: { fonts } }): string => fonts.main};
`;

export const Title = styled.h3`
	color: ${({ theme: { colors } }): string => colors.secondary};
	margin: 0 0 10px;
	font-size: 1.25em;
	font-weight: normal;
	display: block;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	line-height: 1.1;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
`;

export const Text = styled.p`
	margin-bottom: 0;
	margin: 0 0 1.2em;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
`;
