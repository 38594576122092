import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.grey};
  margin-top: 50px;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Close = styled.a`
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;  
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #fff;
  border-radius: 18px;
  border: 1px solid #cfcece;
`;

export const Text = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const Title = styled.h2`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-weight: bold;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const Container = styled.div`

`;

export const TitleContainer = styled.div`
  margin: 20px 5px 5px;

  h2 {
    margin-bottom: 40px;
    text-align: center;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  margin: 20px 5px 10px;
`;

export const ImageContainer = styled.div`
  width: 100%;

  img: {
    width: 100%;
  }
`;
