import styled from 'styled-components';
import { Link } from "gatsby";

export const MainImageContainer = styled.div`
  @media (min-width: 1200px) and (max-width: 1700px) {
    width: 300px;
  }
`

interface StyledImageBackgroundProps {
  imageUrl: string;
}

interface BadgeBlockProps {
  color: string;
}

export const StyledImageBackground = styled.div<StyledImageBackgroundProps>`
  background-image: url(${({ imageUrl }): string => imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: unset;
  border-radius: 8px;
`

export const MainStyledImageBackground = styled.div<StyledImageBackgroundProps>`
  background-image: url(${({ imageUrl }): string => imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 20px 0 50px 0;
  opacity: unset;
  border-radius: 8px;
`

export const MainTextContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 60px;
  position: relative;
  top: 75px;
  padding-bottom: 27px;
`

export const MainText = styled.span`
  width: 304px;
  height: 30px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme: { colors } }): string => colors.white};

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    visibility: hidden;
    width: 404px;
  }
`;

export const MainDesktopTextContainer = styled(MainTextContainer)`
  display: none;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
    margin-bottom: 85px;
    margin-top: -65px;
    padding-top: 0;
    flex-direction: column;
  }
`;
export const MainDesktopTitle = styled(MainText)`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    color: #000;
    visibility: visible;
  }
`;

export const MainDesktopText = styled(MainDesktopTitle)`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-weight: normal;
  }
`;

// import styled from 'styled-components';


export const ServiceLink = styled(Link)`
  text-decoration: none;
`;

export const SmallImageImageContainer = styled.div`
  margin-top: 15px;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin-top: 0;
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    width: 290px;
  }
`

export const LaundryBagImageContainer = styled(SmallImageImageContainer)`
  background: linear-gradient(to left, rgba(39, 78, 95, 0), ${({ theme: { colors } }): string => colors.primary});
  border-radius: 9px;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin-top: 0;
  }
`

export const TextContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 15px;
  position: relative;
  top: 5px;
  padding-bottom: 10px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 404px;
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    width: 290px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleText = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme: { colors } }): string => colors.white};
  margin-bottom: 16px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    visibility: hidden;
  }
`

export const Text = styled.span`
  width: 304px;
  height: 25px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  color: ${({ theme: { colors } }): string => colors.white};

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: none;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }
`

export const BadgeBlock = styled.div<BadgeBlockProps>`
  width: 84px;
  height: 14px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ color }): string => color};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 8px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${({ theme: { colors } }): string => colors.white};

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-size: 12px;
    height: 25px;
    width: 145px;
  }
`;

export const EcoBlock = styled.div`
  width: 84px;
  height: 14px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme: { colors } }): string => colors.green};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 8px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${({ theme: { colors } }): string => colors.white};

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-size: 12px;
    height: 25px;
    width: 145px;
  }
`;

export const CarpetText = styled.div`
  width: 244px;
  height: 25px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  color: ${({ theme: { colors } }): string => colors.white};
  margin-top: 60px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: none;
  }
`;

export const DesktopCarpetText = styled(CarpetText)`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    color: #000;
    display: block;
    font-weight: bold;
    margin-top: 0;
  }
`;

export const DesktopTextContainer = styled(TextContainer)`
  display: none;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: block;
    margin-top: 10px;
    padding-top: 0;
  }
`;
export const DesktopTitle = styled(Text)`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-weight: bold;
    color: #000;
    display: block;
    visibility: visible;
  }
`;

export const DesktopText = styled(DesktopTitle)`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-weight: normal;
  }
`;


// import styled from 'styled-components';
// import { Link } from "gatsby";

// interface MainProps {
//   main?: boolean;
// }

// interface StyledImageBackgroundProps {
//   imageUrl: string;
// }

// export const ServiceLink = styled(Link)`
//   text-decoration: none;
// `;

// export const SmallImageImageContainer = styled.div`
//   margin-top: 15px;
//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     margin-top: 0;
//   }

//   @media (min-width: 1200px) and (max-width: 1700px) {
//     width: 290px;
//   }
// `

// export const StyledImageBackground = styled.div<StyledImageBackgroundProps>`
//   background-image: url(${({ imageUrl }): string => imageUrl});
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   height: 100%;
//   padding: 20px 0 50px 0;
//   opacity: unset;
// `


// export const LaundryBagImageContainer = styled(SmallImageImageContainer)`
//   background: linear-gradient(to left, rgba(39, 78, 95, 0), ${({ theme: { colors } }): string => colors.primary});
//   border-radius: 9px;
//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     margin-top: 0;
//   }
// `

// export const TextContainer = styled.div<MainProps>`
//   display:flex;
//   flex-direction: column;
//   padding-left: 15px;
//   position: relative;
//   ${({ main }) => main ? `
//     padding-top: 15px;
//     padding-bottom: 10px;
//     ` : `
//     top: 5px;
//     @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//       width: 404px;
//     }
//     @media (min-width: 1200px) and (max-width: 1700px) {
//       width: 290px;
//     }
//   `}
// `;

// export const TitleContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `

// export const TitleText = styled.span`
//   font-family: ${({ theme: { fonts } }): string => fonts.main };
//   font-size: 18px;
//   font-weight: bold;
//   line-height: normal;
//   color: ${({ theme: { colors } }): string => colors.white};
//   margin-bottom: 16px;

//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     visibility: hidden;
//   }
// `

// export const Text = styled.span<MainProps>`
//   width: 304px;
//   font-family: ${({ theme: { fonts } }): string => fonts.main };
//   color: ${({ theme: { colors } }): string => colors.white};
  
//   ${({ main }) => main ? `
//     height: 30px;
//     font-size: 20px;
//     font-weight: bold;
//     line-height: normal;

//     @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//       visibility: hidden;
//       width: 404px;
//     }
//     ` : `
//     height: 25px;
//     font-size: 18px;
//     @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//       display: none;
//     }

//     @media (max-width: 700px) {
//       font-size: 16px;
//     }
//   `}
// `


// // export const Text = styled.span`
// //   width: 304px;
// //   height: 30px;
// //   font-family: ${({ theme: { fonts } }): string => fonts.main };
// //   font-size: 20px;
// //   font-weight: bold;
// //   line-height: normal;
// //   color: ${({ theme: { colors } }): string => colors.white};

// //   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
// //     visibility: hidden;
// //     width: 404px;
// //   }
// // `;

// export const EcoBlock = styled.div`
//   width: 84px;
//   height: 14px;
//   border-radius: 4px 0 0 4px;
//   background-color: ${({ theme: { colors } }): string => colors.green};
//   font-family: ${({ theme: { fonts } }): string => fonts.main };
//   font-size: 8px;
//   font-weight: bold;
//   line-height: normal;
//   text-align: center;
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   color: ${({ theme: { colors } }): string => colors.white};

//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     font-size: 12px;
//     height: 25px;
//     width: 145px;
//   }
// `;

// export const CarpetText = styled.div`
//   width: 244px;
//   height: 25px;
//   font-family: ${({ theme: { fonts } }): string => fonts.main };
//   font-size: 18px;
//   color: ${({ theme: { colors } }): string => colors.white};
//   margin-top: 60px;

//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     display: none;
//   }
// `;

// export const DesktopCarpetText = styled(CarpetText)`
//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     color: #000;
//     display: block;
//     font-weight: bold;
//     margin-top: 0;
//   }
// `;

// export const DesktopTextContainer = styled(TextContainer)<MainProps>`
//   display: none;

//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     ${({ main }) => main ? `
//       display: flex;
//       margin-bottom: 85px;
//       margin-top: -65px;
//       padding-top: 0;
//       flex-direction: column;
//     ` : `
//       display: block;
//       margin-top: 10px;
//       padding-top: 0;
//     `}
//   }
// `;

// export const DesktopTitle = styled(Text)<MainProps>`
//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     ${({ main }) => main ? `
//       color: #000;
//       visibility: visible;
//     ` : `
//       font-weight: bold;
//       color: #000;
//       display: block;
//       visibility: visible;
//     `}
//   }
// `;


// export const DesktopText = styled(DesktopTitle)`
//   @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
//     font-weight: normal;
//   }
// `;

// export const PressingImageContainer = styled.div`
//   @media (min-width: 1200px) and (max-width: 1700px) {
//     width: 300px;
//   }
// `
