import React, { FC } from "react";
import Img from "gatsby-image";
import Popup from "reactjs-popup";
import { graphql, StaticQuery } from "gatsby";
import { FormattedHTMLMessage } from "react-intl";

import {
  Text,
  Title,
  TitleContainer,
  TextContainer,
  ImageContainer,
  Close,
  Container,
} from "./style";

interface ZipjetWelcomeModalProps {
  closeModal: (isAnswered?: boolean | null) => void;
  open: boolean;
}

const ZipjetWelcomeModal: FC<ZipjetWelcomeModalProps> = props => {

  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "wast-fusion.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920, maxHeight:670) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data): JSX.Element => (
        <Popup open={props.open} onClose={props.closeModal} modal>
          {(close): JSX.Element => (
            <Container>
              <Close onClick={close}>
                &times;
              </Close>
              <TitleContainer>
                <Title><FormattedHTMLMessage id="welcomezipjettitle" /></Title>
              </TitleContainer>
              <TextContainer>
                <Text>
                  <FormattedHTMLMessage id="welcomezipjetmessage" />
                </Text>
              </TextContainer>
              <ImageContainer>
                <ImageContainer>
                  <Img fluid={data.desktop.childImageSharp.fluid} />
                </ImageContainer>
              </ImageContainer>
            </Container>
          )}
        </Popup>
      )}
    />
  );
};

export default ZipjetWelcomeModal;
