import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.grey};
  margin-top: -30px;
`;

export const IconContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content: center;
`;

export const ImageToIconContainer = styled.div`
  width: 130px;
`;

export const Title = styled.h2`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 20px;
  margin-top: 35px;
`;

export const Text = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
  color: ${({ theme: { colors } }): string => colors.darkGrey};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 15px;
  padding: 1.5em;
  text-align: left;
  width: 100%;
  height: 100%;
`;

export const TextHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Author = styled.div`
  align-items: center;
  color: ${({ theme: { colors } }): string => colors.ligthGrey};
  display: flex;
  flex-direction: row;
`;

export const Quote = styled.div`
  width: 39px;
  height: 53px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 100px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const Slide = styled.div`
  padding: 20px;
  height: 100%;
`;

export const AvatarContainer = styled.div`
  margin-left: 10px;
  width: 40px;
  height: 40px;
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
`;

export const SliderBlock = styled.div`
  width: 90%;

  @media (min-width: 1400px) {
    width: 70%;
  }
`;

export const TextContent = styled.div`
  display: flex;
  align-items: center;
  height: 70%;
`;
