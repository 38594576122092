import React, { FC } from "react";
import {
  BadgeBlock,
  DesktopTextContainer,
  DesktopText,
  DesktopTitle,
  MainImageContainer,
  MainTextContainer,
  MainDesktopText,
  MainStyledImageBackground,
  MainDesktopTextContainer,
  MainDesktopTitle,
  MainText,
  TitleContainer,
  TitleText,
  SmallImageImageContainer,
  StyledImageBackground,
  Text,
  TextContainer,
} from "../style/services/service";
import { ServiceLink } from "../style/services/small-image";

interface Props {
  main?: boolean;
  title: string;
  description: string;
  imageUrl: string;
  badge?: string;
  badgeColor?: string;
}

const ServiceBlock: FC<Props> = ({ main, title, description, imageUrl, badge, badgeColor }) => {

  if (main)
  return (
    <MainImageContainer className="pressing-image-container">
            <>
              <ServiceLink to="/pricing">
                  <MainStyledImageBackground imageUrl={imageUrl}>
                  <MainTextContainer>
                    <MainText>
                      {title}
                    </MainText>
                    <MainText>
                      {description}
                    </MainText>
                  </MainTextContainer>
                  </MainStyledImageBackground>
              </ServiceLink>
              <MainDesktopTextContainer>
                <MainDesktopTitle>
                  {title}
                </MainDesktopTitle>
                <MainDesktopText>
                  {description}
                </MainDesktopText>
              </MainDesktopTextContainer>
            </>
    </MainImageContainer>
  )
  return (
    <SmallImageImageContainer className="laundry-bag-image-container">
            <>
              <ServiceLink to="/pricing">
                  <StyledImageBackground imageUrl={imageUrl}>
                  <TextContainer>
                    <TitleContainer>
                      <TitleText>
                        {title}
                      </TitleText>
                      {badge && badgeColor && (
                        <BadgeBlock color={badgeColor}>
                          {badge}
                        </BadgeBlock>
                        )}
                    </TitleContainer>
                    <Text>
                      {description}
                    </Text>
                  </TextContainer>
                  </StyledImageBackground>
              </ServiceLink>
              <DesktopTextContainer>
                <DesktopTitle>
                  {title}
                </DesktopTitle>
                <DesktopText>
                  {description}
                </DesktopText>
              </DesktopTextContainer>
            </>
    </SmallImageImageContainer>
  )
};
export default ServiceBlock;
