import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import { useIsDesktop } from "../../../hooks/use-is-desktop";
import {
  PartnersWrapper,
  PartnersBackground,
  PartnersTitle,
} from "./style";
import userLanguage from "../../hooks/useLanguage";

const Partners: FC = () => {
  const isDesktop = useIsDesktop();
  const language = userLanguage();
  const { frHomeReferencesBlock, enHomeReferencesBlock } = useStaticQuery(
    graphql`
      query {
        frHomeReferencesBlock: contentfulHomeReferencesBlock(node_locale: {eq: "fr"}) {
          references {
            link
            image {
              secure_url
            }
          }
          title
        }
        enHomeReferencesBlock: contentfulHomeReferencesBlock(node_locale: {eq: "en"}) {
          references {
            link
            image {
              secure_url
            }
          }
          title
        }
      }
    `
  )

  const title = language === "fr" ? frHomeReferencesBlock.title : enHomeReferencesBlock.title;
  const references = language === "fr" ? frHomeReferencesBlock.references : enHomeReferencesBlock.references;
  return (
    <PartnersBackground>
      <PartnersTitle>
        {title}
      </PartnersTitle>
      <PartnersWrapper>
        <Slider autoplay slidesToShow={isDesktop ? 3 : 1} autoplaySpeed={2500} slidesToScroll={1} arrows={false}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {references.map((reference: any, i: number) => (
            <div key={i}>
              <a href={reference.link} rel="noopener noreferrer" target="_blank" key={i}>
                <img src={reference.image[0].secure_url} style={{ objectFit: "contain", maxHeight: 55, margin: "auto" }} key={i} />
              </a>
            </div>
          ))}
        </Slider>
      </PartnersWrapper>
    </PartnersBackground>
  )
};

export default Partners;
