import React, { FC, useEffect } from "react";
import Layout from "../components/layout";
import FooterMap from "../components/home/map";
import HomeHeader from "../components/home/home-header";
import Partners from "../components/home/partners";
import HomeServices from "../components/home/home-services";
import Home4Steps from "../components/home/4-steps";
import Testimonies from "../components/home/testimonies";
import SEO from "../components/seo-home";
import AreaServed from "../components/home/area-served";
import DownloadBar from "../components/home/download-bar";
import AdvantagesBlock from "../components/home/advantage-block"
import OneSignal from 'react-onesignal';

import styled from "styled-components";

const isTest = /api.test.wastdev.com/.test(process.env.GATSBY_API_URL ?? "");

const Background = styled.div`
  background-color: ${({ theme }): string => theme.colors.grey};
`;

const IndexPage: FC = () => {
  const initOneSignal = async (): Promise<void> => {
    try {
      await OneSignal.init({ appId: process.env.GATSBY_ONESIGNAL_APP_ID ?? '', requiresUserPrivacyConsent: false, autoResubscribe: true });
      OneSignal.showSlidedownPrompt().then(async () => {
        OneSignal.provideUserConsent(true);
      })
    } catch (e) {
      console.error(e)
    }
  }
  
  useEffect(() => {
    initOneSignal();
  }, [])


  return (
    <Layout header={false} menuState="home" cart>
      <Background>
        <SEO title="Accueil" />
        <HomeHeader
          home
          services={["home.service.quality", "home.service.eco"]}
        />
        <AdvantagesBlock />
        <DownloadBar />
        <HomeServices />
        <Home4Steps />
        <Partners />
        <Testimonies />
        {/* <FooterMap /> */}
        {isTest && <AreaServed />}
      </Background>
    </Layout>
  )
};

export default IndexPage;
