import React, { FC, useCallback, useState } from "react"
import { graphql, useStaticQuery } from 'gatsby';
import qs from "query-string";
import { Title, ServicesContainer, ServicesCTA, ThreeServicesContainer, Text, ButtonContainer } from '../style/services/services-container'
import ServiceBlock from './service'
import ButtonLink from "../../../ui/button-link";
import ZipjetWelcomeModal from "../../zipjet-welcome-modal";
import userLanguage from "../../hooks/useLanguage";

const HomeServices: FC = () => {

  const [isOpen, setIsOpen] = useState<boolean>(
    true
  );

  const toggleModal = useCallback(async () => {
    localStorage.setItem('popup', "false");
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  let params;
  let storagePopup;
  if (typeof window !== 'undefined') {
    params = qs.parse(location.search);
    storagePopup = localStorage.getItem('popup') || "true";
  }

  const language = userLanguage();

  const { frHomeServiceBlock, enHomeServiceBlock } = useStaticQuery(
    graphql`
      query {
        frHomeServiceBlock: contentfulHomeServiceBlock(node_locale: {eq: "fr"}) {
          services {
            title
            description
            image {
              secure_url
            }
            main
            badge
            badgeColor
          }
          description
          title
          cta
        }
        enHomeServiceBlock: contentfulHomeServiceBlock(node_locale: {eq: "en"}) {
          services {
            title
            description
            image {
              secure_url
            }
            main
            badge
            badgeColor
          }
          description
          title
          cta
        }
      }
    `
  );
  
  const services = language === "fr" ? frHomeServiceBlock.services : enHomeServiceBlock.services;
  const title = language === "fr" ? frHomeServiceBlock.title : enHomeServiceBlock.title;
  const description = language === "fr" ? frHomeServiceBlock.description : enHomeServiceBlock.description;
  const cta = language === "fr" ? frHomeServiceBlock.cta : enHomeServiceBlock.cta;

  return (
    <>
    <ServicesContainer id="services">
      { params && params.fromZipjet && storagePopup === "true" ? <ZipjetWelcomeModal closeModal={toggleModal} open={isOpen} /> : ""}
      <Title>{title}</Title>
      <Text>{description}</Text>
      {typeof window !== "undefined" && screen.width < 1200 ? (
        <ThreeServicesContainer>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {services.map((service: any) => (
            <ServiceBlock
              main={service.main}
              title={service.title}
              description={service.description}
              imageUrl={service.image && service.image[0] && service.image[0].secure_url}
              badge={service.badge}
              badgeColor={service.badgeColor}
              key={JSON.stringify(service)}
            />
          ))}
          {/* <PressingBlock />
          <TwoServicesContainer>
            <LaundryBagBlock />
            <CarpetBlock />
          </TwoServicesContainer> */}
        </ThreeServicesContainer>
      ) : (
        <ThreeServicesContainer>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {services.map((service: any) => (
            <ServiceBlock
              key={JSON.stringify(service)}
              main={service.main}
              title={service.title}
              description={service.description}
              imageUrl={service.image && service.image[0] && service.image[0].secure_url}
              badge={service.badge}
              badgeColor={service.badgeColor}
            />
          ))}
          {/* <PressingBlock />
          <LaundryBagBlock />
          <CarpetBlock /> */}
        </ThreeServicesContainer>
      )}
      <ServicesCTA>
        <ButtonContainer>
          <ButtonLink link="/pricing" text={cta} borderStyle="10px" width="100%" padding="0 15px" />
        </ButtonContainer>
      </ServicesCTA>
    </ServicesContainer>
  </>
  )
}

export default HomeServices
