import React, { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  ColumnLeft,
  ColumnRight,
  TextContainer,
  Title,
  Text,
  FormContainer,
  Fieldset,
  InputContainer,
  Input,
  InputButtonLeft,
  InputButtonRight,
} from "./style";

import AddressAutocomplete from "../../address-autocomplete";
import ConfirmAddressModal from "../../confirm-address-modal";
import { useToast, TOAST_TYPE } from "../../../ui/toast";
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
} from "../../../store/address/types";

interface AreaServedProps {
  openAddressModal: (open: boolean) => void;
  setAddress: (addr: ExtendedGoogleMapsPlace) => void;
}

export const Inner: FC<AreaServedProps> = ({
  openAddressModal,
  setAddress,
}): JSX.Element => {
  const intl = useIntl();
  const { showToast } = useToast();

  const onNewsletterSubscribe = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    showToast(<FormattedMessage id="comingsoon" />, {
      type: TOAST_TYPE.ERROR,
    });
  };

  return (
    <Container>
      <ColumnLeft>
        <TextContainer>
          <Title>
            <FormattedMessage id="newsletter.title" />
          </Title>
          <Text>
            <FormattedMessage id="newsletter.input" />
          </Text>
        </TextContainer>
        <FormContainer onSubmit={onNewsletterSubscribe}>
          <Fieldset>
            <InputContainer>
              <Input
                type="email"
                name="email"
                placeholder={intl.formatMessage({
                  id: "newsletter.placeholder",
                })}
              />
              <InputButtonLeft
                type="submit"
                value={intl.formatMessage({ id: "newsletter.button" })}
              />
            </InputContainer>
          </Fieldset>
        </FormContainer>
      </ColumnLeft>
      <ColumnRight>
        <TextContainer>
          <Title>
            <FormattedMessage id="area.title" />
          </Title>
          <Text>
            <FormattedMessage id="area.description" />
          </Text>
        </TextContainer>
        <FormContainer>
          <Fieldset>
            <InputContainer>
              <AddressAutocomplete
                inputId="address-autocomplete-footer"
                placeholder={intl.formatMessage({
                  id: "home.google.autocomplete.placeholder",
                })}
                onPlaceChange={(newAddress): void => {
                  setAddress(newAddress);
                  openAddressModal(true);
                }}
              />
              <InputButtonRight
                type="submit"
                value={intl.formatMessage({ id: "area.button" })}
              />
            </InputContainer>
          </Fieldset>
        </FormContainer>
      </ColumnRight>
    </Container>
  );
};

const AreaServed: FC = () => {
  const [confirmAddressModalIsOpen, openAddressModal] = useState<boolean>(
    false
  );
  const [address, setAddress] = useState<ExtendedGoogleMapsPlace>({
    coordinates: PARIS_COORD,
  });

  return (
    <>
      <ConfirmAddressModal
        open={confirmAddressModalIsOpen}
        closeModal={(): void => openAddressModal(false)}
        initialPlace={{ ...address }}
      />
      <Inner setAddress={setAddress} openAddressModal={openAddressModal} />
    </>
  );
};

export default AreaServed;
